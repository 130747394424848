<template>
  <div class="header-nav-container">
    <transition name="fade">
      <div v-if="opened" class="dark-filter" @click="handleLinkClicked(!opened)" />
    </transition>
    <nav class="nav" :class="{ opened }">
      <div
        v-for="{ id, title, link, dropdown } of menus"
        :key="id"
        class="link-wrapper"
        :class="{ dropdown }"
        :to="link"
        @click="dropdown || handleLinkClicked()"
      >
        <Accordion
          v-slot="{ toggle }"
          class="dropdown-wrapper"
          :disabled="dropdown === undefined"
          mobile-only="keep-arrow"
        >
          <div class="menu" @click="toggle">
            <NuxtLink
              :class="{ dropdown }"
              :to="link"
              @click="
                !breakpoints.isMobile
                  ? handleLinkClicked(dropdown === 'destinations' ? !opened : false)
                  : ''
              "
              @mouseover="
                !breakpoints.isMobile
                  ? handleLinkClicked(dropdown === 'destinations' ? true : false)
                  : ''
              "
            >
              <div class="title">{{ title }}</div>
            </NuxtLink>
            <Icon
              v-if="dropdown"
              class="icon accordion-arrow"
              name="arrow-dropdown"
              fill="var(--color-primary-black)"
            />
          </div>

          <!-- The 'dropdown-hitbox' is the area that can be hovered to trigger the dropdown.
             It is just a bit bigger than the dropdown itself, to include the space between it
             and the header, and also for quality of life so that the dropdown doesn't disappear
             as soon as the mouse leaves the dropdown. -->
          <div
            v-if="dropdown"
            class="dropdown-hitbox"
            :class="opened ? 'opened' : 'closed'"
            @mouseleave="handleLinkClicked(false)"
          >
            <div class="accordion-content">
              <!-- By having the dropdown element contained in the menu, we can trigger it using
             only CSS by using :hover on the menu. -->
              <HeaderDropdownDestinations @link-clicked="handleLinkClicked" />
            </div>
          </div>
        </Accordion>
      </div>
      <Menu v-if="breakpoints.isMobile" is-mobile></Menu>
    </nav>
  </div>
</template>

<script lang="ts" setup>
import HeaderDropdownDestinations from '@/components/Header/Dropdown/HeaderDropdownDestinations.vue';
import { NavButton } from '@/lib/strapi-types/components/NavButton';

const { opened = false } = defineProps<{
  menus: NavButton[];
  opened?: boolean;
}>();
const emit = defineEmits<{
  (event: 'update:opened', value: boolean): void;
}>();
const breakpoints = useBreakpoints();
function handleLinkClicked(value = false) {
  // min value 10 is needed for mobile
  const timeoutValue = value ? 10 : 500;

  setTimeout(() => {
    emit('update:opened', value);
  }, timeoutValue);
}
</script>

<style lang="scss" scoped>
@use 'sass:list';
@use '$/animation.scss';
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/shadows.scss';
@use '$/z-index.scss';

.header-nav-container {
  height: 100%;

  .dark-filter {
    display: none;

    @include breakpoints.mobile() {
      @include animation.vue-transition-fade();

      position: absolute;
      z-index: z-index.$mobile-nav;
      top: 100%;
      left: 0;

      display: flex;

      width: 100vh;
      height: 100vh;

      background-color: rgb(0 0 0 / 20%);
      box-shadow: inset shadows.$page;
    }
  }

  .nav {
    display: flex;
    gap: 40px;
    height: 100%;

    @include breakpoints.mobile() {
      // Since the nav is on top of the header, we use an inset shadow to replicate the one the header
      // should have if it was not on top of the nav.
      // But we must hide the inset shadow on the left and right corner, which we do using left
      // shift + padding, and by shifting the shadow X property.

      $shadow: shadows.$menu;
      $shadow-blur: list.nth($shadow, 3);

      $padding: 16px;

      position: absolute;
      z-index: z-index.$mobile-nav;

      // Here, the nearest relative parent is the header, so we use 100% (of the header's height) to
      // position the nav below the header.
      top: 100%;
      left: -$shadow-blur * 2;
      transform: translateX(-100%);

      overflow-y: auto;
      flex-direction: column;
      gap: 24px;

      width: calc(275px - #{$padding * 2});
      height: calc(100vh - #{metrics.$header-height-mobile});
      padding: $padding;
      padding-left: $padding + $shadow-blur * 2;

      background-color: white;
      box-shadow: inset list.set-nth($shadow, 1, $shadow-blur);

      transition: transform 0.3s animation.$easing-ease-out-cubic;
    }

    &.opened {
      @include breakpoints.mobile() {
        transform: translateX(0);
      }
    }

    .link-wrapper {
      flex-direction: column;

      .dropdown-wrapper {
        flex-direction: column;
        height: 100%;

        .menu {
          display: flex;
          gap: 12px;
          align-items: center;
          height: 100%;

          @include breakpoints.mobile() {
            height: auto;
          }

          &:hover {
            cursor: pointer;
          }

          .title {
            font-size: 20px;

            @include breakpoints.mobile() {
              font-size: 18px;
              line-height: 22px;
            }
          }

          &:hover .title {
            font-weight: 700;
            letter-spacing: -0.21px;

            @include breakpoints.mobile() {
              font-weight: inherit;
              letter-spacing: inherit;
            }
          }

          &:not(.dropdown):hover .title {
            text-decoration: underline;
            text-decoration-thickness: 1px;
            text-underline-offset: 5px;

            @include breakpoints.mobile() {
              text-decoration: inherit;
            }
          }

          .icon {
            transition: transform 0.15s animation.$easing-ease-out-cubic;
          }

          &:hover .icon {
            transform: rotate(180deg);
          }
        }

        &:not(.opened) .menu .icon {
          transform: initial;
        }

        .dropdown-hitbox {
          cursor: initial;

          position: absolute;
          top: metrics.$header-height;
          left: 70px;

          display: none;

          padding: 16px;

          @include breakpoints.mobile() {
            position: static;
            display: flex;
            padding: 0;
          }

          .accordion-content {
            background-color: white;
            box-shadow: shadows.$page;

            @include breakpoints.mobile() {
              background: none;
              box-shadow: none;
            }
          }
        }

        .dropdown-hitbox.opened {
          display: flex;
        }

        .dropdown-hitbox.closed {
          display: none !important;
        }

        &:hover .dropdown-hitbox {
          z-index: z-index.$header-dropdown;
          display: flex;
        }
      }
    }
  }
}
</style>
