<template>
  <div
    ref="element"
    class="accordion"
    :class="{
      disabled,
      opened: !disabled && opened,
      'mobile-only': mobileOnly,
      'keep-arrow': mobileOnly === 'keep-arrow'
    }"
  >
    <slot v-bind="{ opened, toggle }" />
  </div>
</template>

<script lang="ts" setup>
const { disabled = false, mobileOnly = false } = defineProps<{
  disabled?: boolean;
  mobileOnly?: boolean | 'keep-arrow';
}>();

const element = $ref<HTMLDivElement>();
let opened = $ref(false);
let contentHeight = $ref<number>();
let observer = $ref<ResizeObserver>();

const reverseMargin = $computed(() => `-${contentHeight ?? 0}px`);

onBeforeUnmount(() => {
  if (observer) {
    observer.disconnect();
  }
});

watchEffect(() => {
  if (!element) {
    return;
  }

  if (!observer) {
    observer = new ResizeObserver(onContentResize);
  } else {
    observer.disconnect();
  }

  observer.observe(element);
});

function onContentResize(): void {
  const content = element?.querySelector('.accordion-content');
  contentHeight = content?.scrollHeight;
}

function toggle(): void {
  opened = !opened;
}
</script>

<style lang="scss" scoped>
@use '$/animation.scss';
@use '$/breakpoints.scss';

// To allow Accordion nesting, we must prevent deep classes to affect nested accordions. To do so,
// we use > to select only children of 2 maximum deepness.
// This is not really clean, but the only way (to my knowledge) to properly replace this would be
// with JavaScript to lookup the right elements directly.
@mixin selector($selector) {
  & > :deep(#{$selector}),
  & > :deep(* > #{$selector}) {
    @content;
  }
}

@mixin base() {
  @include selector('.accordion-arrow') {
    transition: transform 0.2s animation.$easing-ease-out-cubic;
  }

  @include selector('.accordion-content') {
    // height: % doesn't work and 'auto' can't be transitioned to
    // transform: doesn't change the size of the section
    // margin-bottom: works perfectly X D but % is width percent so we need JS for height :(
    margin-bottom: v-bind(reverseMargin);
    transition: margin-bottom 0.35s animation.$easing-ease-out-cubic;
  }

  overflow-y: hidden;

  &.opened {
    @include selector('.accordion-arrow') {
      transform: rotate(180deg);
    }

    @include selector('.accordion-content') {
      margin-bottom: 0;
    }
  }
}

.accordion {
  &:not(.mobile-only, .disabled) {
    @include base();
  }

  &.mobile-only {
    &:not(.disabled) {
      @include breakpoints.mobile() {
        @include base();

        &:not(.keep-arrow) {
          @include selector('.accordion-arrow') {
            display: flex;
          }
        }
      }
    }

    &:not(.keep-arrow) {
      @include selector('.accordion-arrow') {
        display: none;
      }
    }
  }
}
</style>
