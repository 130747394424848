<template>
  <div class="container">
    <div class="destinations">
      <Accordion
        v-for="continent of headerDropdowncontinents"
        :key="continent.title"
        v-slot="{ toggle }"
        class="category"
        mobile-only
      >
        <NuxtLink
          v-if="!breakpoints.isMobile"
          :to="breakpoints.isMobile ? null : `/${continent.slug}/`"
          @click="notifyHeaderNav"
        >
          <NuxtImg
            v-if="continent?.image"
            provider="twicpics"
            :src="continent.image?.url + `?twic=v1/output=webp/resize=165x64`"
            :alt="continent.image?.title"
            class="image"
          />
        </NuxtLink>
        <div v-if="breakpoints.isMobile" class="title-container" @click="toggle">
          <NuxtLink class="title" :to="null">
            {{ continent.title }}
          </NuxtLink>
          <Icon class="arrow accordion-arrow" name="arrow-dropdown" />
        </div>
        <div v-if="!breakpoints.isMobile" class="title-container" @click="notifyHeaderNav">
          <NuxtLink class="title" :to="`/${continent.slug}/`">
            {{ continent.title }}
          </NuxtLink>
          <Icon class="arrow accordion-arrow" name="arrow-dropdown" />
        </div>
        <div class="countries accordion-content">
          <NuxtLink
            v-for="country of continent.countries"
            :key="country.slug"
            class="country"
            :to="`/${continent.slug}/${country.slug}/`"
            @click="notifyHeaderNav"
          >
            {{ country.name }}
          </NuxtLink>
        </div>
      </Accordion>

      <!-- The class category is on purpose to have same styling as above -->
      <Accordion v-if="breakpoints.isMobile" v-slot="{ toggle }" class="category" mobile-only>
        <div class="title-container" @click="toggle">
          <span class="title">Thématiques</span>
          <Icon class="arrow accordion-arrow" name="arrow-dropdown" />
        </div>
        <div class="countries accordion-content">
          <NuxtLink
            v-for="theme of headerDropdownThemes"
            :key="theme.slug"
            class="country"
            :to="`/themes/${theme.slug}/`"
          >
            {{ theme.name }}
          </NuxtLink>
        </div>
      </Accordion>
    </div>
    <div class="themes">
      <div class="title">Nos thématiques</div>
      <div class="content">
        <div v-for="(theme, index) of navThematics" :key="theme.name" class="theme">
          <div v-if="index > 0" class="point" />
          <NuxtLink
            :to="
              theme.url.startsWith('http')
                ? `${theme.url.replace(/^\//, '')}`
                : `/inspirations/${theme.url.replace(/^\//, '')}/`
            "
            class="theme-link"
            :target="theme.url.startsWith('http') ? '_blank' : '_self'"
          >
            {{ theme?.name }}
          </NuxtLink>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const emit = defineEmits<{
  (event: 'linkClicked'): void;
}>();
const breakpoints = useBreakpoints();
const headerStore = useHeaderStore();

function notifyHeaderNav() {
  emit('linkClicked');
}

const { headerDropdowncontinents, headerDropdownThemes, navThematics } = storeToRefs(headerStore);
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/colors.scss';
@use '$/mouse.scss';

.theme-link {
  color: white;
  text-decoration: underline;
  text-underline-offset: 3px;

  &:hover {
    font-weight: normal;
    text-decoration: none;
  }
}

.container {
  flex-direction: column;

  .destinations {
    gap: 20px;
    padding: 25px 40px 22px;

    @include breakpoints.mobile() {
      flex-direction: column;
      gap: 16px;
      padding: 16px 0 0 16px;
    }

    .category {
      flex-direction: column;
      gap: 6px;
      width: 165px;

      @include breakpoints.mobile() {
        gap: 0;
        width: auto;
      }

      .image {
        width: 100%;
        height: 64px;
        object-fit: cover;
        background-position: center;

        @include breakpoints.mobile() {
          display: none;
        }
      }

      .title-container {
        gap: 12px;
        align-items: center;

        .title {
          cursor: pointer;
          margin-top: 8px;
          font-size: 16px;
          font-weight: 700;

          @include breakpoints.mobile() {
            margin-top: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
          }
        }

        .arrow {
          display: none;

          @include breakpoints.mobile() {
            display: flex;
          }
        }
      }

      &.opened .title-container .title {
        @include breakpoints.mobile() {
          font-weight: 800;
        }
      }

      .countries {
        flex-direction: column;
        gap: 4px;

        @include breakpoints.mobile() {
          gap: 8px;
          padding-top: 12px;
          padding-left: 16px;
        }

        .country {
          @include mouse.clickable-opacity();

          font-size: 14px;
          line-height: 16px;

          @include breakpoints.mobile() {
            font-size: 14px;
            line-height: 17px;
          }

          &:hover {
            font-weight: 600;

            @include breakpoints.mobile() {
              font-weight: initial;
            }
          }
        }
      }
    }
  }

  .themes {
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 42px;
    padding: 12px;

    color: white;

    background-color: colors.$primary-black;

    @include breakpoints.mobile() {
      display: none;
    }

    .title {
      font-size: 18px;
      font-weight: 800;
      line-height: 36px;
      color: colors.$primary-yellow;
    }

    .content {
      gap: 8px;

      .theme {
        @include mouse.clickable-opacity();

        align-items: center;
        font-size: 16px;

        .point {
          width: 4px;
          height: 4px;
          margin-right: 8px;

          background-color: white;
          border-radius: 50%;
        }

        &:hover {
          font-weight: 600;
          font-weight: normal;
        }
      }
    }
  }
}
</style>
